import React, { useCallback, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useQuery } from "react-query";
import useAccount from "classes/Accounts/hooks/useAccount";
import PageLoad from "../PageLoad/PageLoad";
import "./Wrapper.css";
import { t } from "i18next";
import ServError from "components/ServError/ServError";
import Welcome from "components/Welcome/Welcome";
import useUnread from "classes/Accounts/hooks/useUnread";
import useTemplate from "hooks/useTemplate";
import usePlatform from "hooks/usePlatform";
import useMenuState from "hooks/useMenuState";
import Menu from "components/Menu/Menu";
import MobileNavBar from "components/MobileNavBar/MobileNavBar";
import useCurPage from "hooks/useCurPage";
import useTheme from "hooks/useTheme";
import useManifest from "hooks/useManifest";
import IntroPopper from "components/IntroPopper/IntroPopper";
import Helper from "Pages/Tutorials/Helper";
import TourEditor from "Pages/Tutorials/TourEditor";
import RoutesWrapper from "Pages/RoutesWrapper";
import getMenuItems, { getMenuItem } from "components/Menu/MenuItems";
import { useAccountContext } from "providers/AccountProvider";
import { useMediaQuery } from "react-responsive";
import { useFilterContext } from "providers/FilterProvider";
import FilterMenuMobile from "components/HeadBar/FilterMenuMobile";

export default function Wrapper(props) {
	let home_page = "/news";
	const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
	localStorage.setItem("scrollPosition", 0);

	if (window.location.pathname !== home_page) {
		let base_page = window.location.pathname.split("/")[1];
		home_page = "/" + base_page;
	}
	const { platform, setPlatform } = usePlatform();
	const { template, fetchTemplate } = useTemplate();
	const { updateLastCo, isSup, getSettings } = useAccount();
	const { updatePageTitle } = useUnread();
	const { getCurPage, setCurPage } = useCurPage(home_page);
	const [IsInitied, setIsInitied] = useState(false);
	const [SmallScreen, setSmallScreen] = useState(false);
	const { isMenuOpen, setIsMenuOpen } = useMenuState(false);
	const { applyThemes } = useTheme();
	const { fetchManifest } = useManifest();
	const [menu, setMenu] = useState([]);
	const { accountObj, setUserOrAdmin } = useAccountContext();
	const { isFilterOpen, setIsFilterOpen, setCategoriesSelected, setCategoryIdsArray, setSiteSelected } = useFilterContext();

	if (document.body.clientWidth < 700) {
		setUserOrAdmin("user");
	}

	const account = accountObj.account;

	const handleResize = useCallback(() => {
		if (
			(document.body.clientHeight <= 800 ||
				document.body.clientWidth <= 700) &&
			!SmallScreen
		)
			setSmallScreen(true);
		else if (
			document.body.clientHeight > 800 &&
			document.body.clientWidth > 700 &&
			SmallScreen
		)
			setSmallScreen(false);
	}, [setSmallScreen, SmallScreen]);

	React.useEffect(() => {
		if (!IsInitied) {
			handleResize();
			setIsInitied(true);
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [IsInitied, setIsInitied, handleResize]);

	useEffect(() => {
		if (
			account &&
			account.Rights &&
			account.Rights.length > 0 &&
			account.Rights[0] !== null
		) {
			const settings = getSettings();
			const rssPerm = template.getAppFunction("PeoplePage.Rss");
			const menuItems = getMenuItems(account.Rights, settings, rssPerm);
			setMenu(menuItems);
		}
		return () => {
			setMenu([]);
		};
	}, [account.Rights]);

	function changePage(page, force) {
		localStorage.setItem("scrollPosition", 0);
		setCurPage(page, page.url === CurPageUrl || force);
		setCategoriesSelected(null);
		setCategoryIdsArray(null);
		setSiteSelected(null);
		if (isMenuOpen) setIsMenuOpen(false);
		setIsInitied(true); // used to update active page on menu from CustomPageLink click
	}

	const { isLoading, error, data, isFetching } = useQuery(
		["Template", "CurPage", account?.CompanyId],
		() => fetchTemplate(),
		{
			onSuccess: (resp) => {
				updateLastCo();
				updatePageTitle(false);
				applyThemes();
				fetchManifest(account.CompanyId, account.SiteId);
			},
			refetchOnWindowFocus: false,
			retryOnMount: false,
			refetchOnMount: false,
		}
	);

	if (isLoading || isFetching || !data)
		return (
			<div className="w-100 h-100 d-flex align-items-center justify-content-center">
				<PageLoad style={{ borderRadius: 0 }} text={<Welcome />} />
			</div>
		);

	if (error)
		return <ServError title={t("Desktop.LOAD_ERROR")} reloadBtn={true} />;

	let CurPageUrl = getCurPage();
	let CurPage = getMenuItem(CurPageUrl);

	return (
		<Router getUserConfirmation={() => { }}>
			<div className="App d-flex flex-column flex-lg-row">
				{(!CurPage?.hideMenu || document.body.clientWidth < 700) &&
					(isMenuOpen || document.body.clientWidth > 700) && (
						<Menu
							id="main-menu"
							platform={platform}
							setPlatform={setPlatform}
							isMenuOpen={isMenuOpen}
							setIsMenuOpen={setIsMenuOpen}
							items={menu}
							appTemplate={template}
							cur={CurPageUrl}
							callback={changePage}
						/>
					)}
				{(!isMenuOpen ||
					(isMenuOpen && document.body.clientWidth > 700)) && (
						<RoutesWrapper
							platform={platform}
							setPlatform={setPlatform}
							isMenuOpen={isMenuOpen}
							setIsMenuOpen={setIsMenuOpen}
							items={menu}
							appTemplate={template}
							curPage={CurPageUrl}
							callback={changePage}
							setLangCode={props.setLangCode}
						/>
					)}
				{isMobile && (
					<MobileNavBar
						items={menu}
						callback={changePage}
						curPage={CurPageUrl}
						platform={platform}
						setPlatform={setPlatform}
						isMenuOpen={isMenuOpen}
						setIsMenuOpen={setIsMenuOpen}
					/>
				)}
				{isSup("EloAdmin") >= 0 && (
					<>
						<IntroPopper />
						<TourEditor />
					</>
				)}
				{(isMobile && isFilterOpen) && (
					<FilterMenuMobile />)}
				<Helper />
			</div>
		</Router>
	);
}
